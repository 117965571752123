// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-drabuziai-js": () => import("./../../../src/pages/drabuziai.js" /* webpackChunkName: "component---src-pages-drabuziai-js" */),
  "component---src-pages-firminis-stilius-js": () => import("./../../../src/pages/firminis-stilius.js" /* webpackChunkName: "component---src-pages-firminis-stilius-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iskabos-js": () => import("./../../../src/pages/iskabos.js" /* webpackChunkName: "component---src-pages-iskabos-js" */),
  "component---src-pages-placiaformate-spauda-js": () => import("./../../../src/pages/placiaformate-spauda.js" /* webpackChunkName: "component---src-pages-placiaformate-spauda-js" */),
  "component---src-pages-spauda-js": () => import("./../../../src/pages/spauda.js" /* webpackChunkName: "component---src-pages-spauda-js" */),
  "component---src-pages-stendai-js": () => import("./../../../src/pages/stendai.js" /* webpackChunkName: "component---src-pages-stendai-js" */),
  "component---src-pages-transportas-js": () => import("./../../../src/pages/transportas.js" /* webpackChunkName: "component---src-pages-transportas-js" */),
  "component---src-pages-veliavos-js": () => import("./../../../src/pages/veliavos.js" /* webpackChunkName: "component---src-pages-veliavos-js" */)
}

